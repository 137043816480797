import { Link, useFetcher } from '@remix-run/react'
import { json } from '@vercel/remix'
import { useEffect } from 'react'
import { $path } from 'remix-routes'
import dayjs from '~/libs/dayjs'
import { getStats } from '~/models/stats.server'

export const loader = async () => {
  const stats = await getStats()
  return json(stats)
}

export const StatsPanel = () => {
  const fetcher = useFetcher<typeof loader>()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetcher.load('/resources/stats')
  }, [])

  return (
    <div className="text-right text-sm">
      <div>最終データ更新日: {fetcher.data ? dayjs(fetcher.data.lastUpdatedAt).tz().format('YYYY月M月D日') : '-'}</div>
      <div>
        <span className="hover:underline">
          <Link to={$path('/chemicals')}>
            登録薬品数: {fetcher.data ? fetcher.data.numOfChemicals.toLocaleString() : '-'}
            <small>件</small>
          </Link>
        </span>
      </div>
    </div>
  )
}

export default function StatsPage() {
  return <StatsPanel />
}
